import {addLoader, removeLoader} from './components/loading-dots'

const loadMoreButtons = document.querySelectorAll('[data-load-more-content]');

/**
 * Updates laod more button
 * @param {Element} button 
 * @param {Boolean} isMoreContent 
 * @param {Number} last 
 */
const updateLoadMoreButton = (button, isMoreContent, last) => {
    // update number of the last appended item
    if (last) button.setAttribute('data-last-item', last);
    // remove button
    if (!isMoreContent) button.remove();
}

/**
 * Appends content at the end of wrapper element
 * @param {String} content 
 * @param {Element} wrapper 
 */
const appendContent = (content, wrapper) => {
    wrapper.innerHTML += content;
}

/**
 * Gets more content
 * @param {String} source
 * @param {String} lastItem 
 * @param {Function} callback 
 * @returns 
 */
const getContent = (source, lastItem, wrapper, callback) => {
    lastItem = ((lastItem) ? parseInt(lastItem) : 0);

    // Returns if there is no more content
    if (!lastItem) return;

    // add loading animation
    addLoader(wrapper);

    const url = new URL(window.location.origin + source);
    url.searchParams.append('end', lastItem);

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = "json";
    xhr.onload = () => {
        if (xhr.status === 200) {
            callback(xhr.response);
        }
    };
    xhr.send();
}

/**
 * Handles click on load more button
 * @param {Element} button 
 * @returns 
 */
const handleButtonClick = (button) => {
    if (button.getAttribute('data-load-more-content') !== 'true') {
        return;
    } else {
        const source = button.getAttribute('data-source');
        const wrapper = document.getElementById(button.getAttribute('aria-controls'));
        const lastItem = button.getAttribute('data-last-item');
        if (source && wrapper) {

            getContent(source, lastItem, wrapper, (data) => {
                const content = data.content;
                const isMoreContent = data.moreContent;
                const last = parseInt(data.last);

                if (content) appendContent(content, wrapper);
                // hide more button if there is no more content
                updateLoadMoreButton(button, isMoreContent, last);
            });
            // remove loading animation
            removeLoader();

        }
    }
}

// Handle button click
if (loadMoreButtons.length) {
    loadMoreButtons.forEach(button => {
        button.addEventListener('click', () => {
            handleButtonClick(button);
        });
    });
}